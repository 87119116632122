import ReactDOM from 'react-dom/client';
import './styles/index.css';
import {BrowserRouter} from 'react-router-dom';
import Router from "./routers/Router";
import Header from './templates/header';
import Copyright from './templates/copyright';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

document.addEventListener("DOMContentLoaded", function() {
  const rootRef = document.getElementById('root');
  if(window.innerWidth <= 1330) {
    rootRef?.setAttribute('type', 'mobile');
    rootRef?.setAttribute('style', '--top-position-general: 10vh; --display-map-value: none');
  } else {
    rootRef?.setAttribute('type', 'desktop');
    rootRef?.setAttribute('style', '--top-position-general: 20vh; --display-map-value: block');
  }
});

root.render(
  <BrowserRouter>
    <Header />
    <Router />
  </BrowserRouter>
);


